import React, { useRef, useEffect } from "react"
import Header from "../components/header"
import { Flex, Box, useColorModeValue, useColorMode } from "@chakra-ui/react"
import { StaticImage } from "gatsby-plugin-image"
import "./splash.css";

export default function Layout({ children }) {
    const { setColorMode } = useColorMode()
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            if (window.localStorage.getItem("chakra-ui-color-mode-set") != "true") {
                const date = new Date();
                const lightMode = 6 <= date.getHours() && date.getHours() < 6;
                setColorMode(lightMode ? 'light' : 'dark')
                window.localStorage.setItem("chakra-ui-color-mode-set", "true")
            }
            return;
        }
    });

    return (
        <Flex flexDirection="column" height="100vh" maxHeight="-webkit-fill-available">
            <Header />
            {children}
            <Flex
                key={`loader`}
                id="___loader"
                className={firstRender && 'in'}
                style={{
                    alignItems: "center",
                    backgroundColor: useColorModeValue('#FCFCFA', 'black'),
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    /*backgroundImage: "url(/images/pattern-chill.svg)"*/
                }}
            >
                <Box id="___splash_1">
                    <StaticImage placeholder={null} src="../images/logomark_render.png" id="___splash_1" display="block" width="200" alt="JJR" />
                </Box>
                <Box mt="9" px="6" width="100%" maxWidth="450px"><img id="___splash_2" display="block" src='/images/logotype.svg' width="100%" alt="Jonathan Joseph Rosse Jewelry" /></Box>
            </Flex>
        </Flex >
    )
}
